// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Research from './components/Research';
import Footer from './components/Footer';
import Notification from './components/Notification';
import './App.css';


function App() {
  return (
    <Router>
      <div className="content-wrapper">
        <Helmet>
          <title>BrightLyf - Your AI-Powered Mental Health Companion</title>
          <meta name="description" content="BrightLyf is revolutionizing mental health in the USA with AI-powered support and personalized care." />
        </Helmet>
        <Notification />
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <Features />
              <Research />
            </>
          } />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}
export default App;