// components/Hero.js
import React, { useEffect, useRef, useState } from 'react';
import Typed from 'typed.js';
import { createClient } from '@supabase/supabase-js';
import { SpeedInsights } from "@vercel/speed-insights/react"

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

function Hero() {
  const typedRef = useRef(null);
  const [email, setEmail] = useState('');
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    const typed = new Typed(typedRef.current, {
      strings: [
        'Revolutionizing Mental Health in the USA',
        'Your AI-Powered Mental Health Companion',
        'Empowering You Towards Better Well-being'
      ],
      typeSpeed: 50,
      backSpeed: 30,
      backDelay: 1000,
      startDelay: 1000,
      loop: true
    });

    return () => {
      typed.destroy();
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setNotification({ message: 'Please enter a valid email address.', type: 'error' });
      return;
    }

    try {
      // First, check if the email already exists
      const { data, error } = await supabase
        .from('waitlist')
        .select('email')
        .eq('email', email)
        .single();

      if (error && error.code !== 'PGRST116') {
        // PGRST116 means no rows returned, which is fine for us
        throw error;
      }

      if (data) {
        setNotification({ message: 'This email is already registered.', type: 'info' });
        return;
      }

      // If email doesn't exist, insert it
      const { error: insertError } = await supabase
        .from('waitlist')
        .insert([{ email: email }]);

      if (insertError) throw insertError;

      setNotification({ message: 'Thank you for joining our waitlist!', type: 'success' });
      setEmail('');
    } catch (error) {
      console.error('Error:', error);
      setNotification({ message: 'An error occurred. Please try again.', type: 'error' });
    }
  };

  return (
    <div className="hero">
      <div className="logo">BrightLyf</div>
      <div className="tagline"><span ref={typedRef}></span></div>
      <form onSubmit={handleSubmit} className="waitlist-form">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
          className="waitlist-input"
        />
        <button type="submit" className="cta-button">Join the Waitlist</button>
      </form>
      {notification && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}
      <SpeedInsights />
    </div>
  );
}

export default Hero;