// components/Footer.js
import React from 'react';

function Footer() {
  return (
    <footer id="contact">
      <p>&copy; 2024 BrightLyf. All rights reserved.</p>
      <p>Contact: support@brightlyf.com </p>
    </footer>
  );
}

export default Footer;
