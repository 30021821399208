// components/Research.js
import React from 'react';

const researchData = [
  {
    title: "Improving AI Algorithms",
    description: "We continuously refine our AI algorithms to ensure more accurate, empathetic, and relevant responses, making Bright a more effective companion."
  },
  {
    title: "Developing New Features",
    description: "Our research team is dedicated to creating new features that enhance user engagement and provide deeper insights into mental health."
  },
  {
    title: "User-Centered Innovation",
    description: "Feedback from our users is invaluable. We incorporate user insights into our development process to continually improve BrightLyf's offerings."
  }
];

function Research() {
  return (
    <section id="research" className="section">
      <h2>Ongoing Research</h2>
      <p className="section-intro">Our commitment to advancing mental health through innovation and research is at the core of BrightLyf.</p>
      <div className="research-grid">
        {researchData.map((item, index) => (
          <div key={index} className="research-item">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Research;