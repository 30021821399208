// components/Notification.js
import React, { useState, useEffect } from 'react';

function Notification() {
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  // This function can be called from other components to show a notification
  const showNotification = (message, type) => {
    setNotification({ message, type });
  };

  if (!notification) return null;

  return (
    <div id="notification" className={notification.type}>
      {notification.message}
    </div>
  );
}

export default Notification;
