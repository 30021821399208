// components/Features.js
import React from 'react';

const featuresData = [
  {
    title: "Personalized Mental Health Support",
    description: "Receive tailored advice and support based on your unique mental health profile. Our AI-driven insights help you navigate your mental health journey with confidence."
  },
  {
    title: "Mood Tracking",
    description: "Monitor your mood over time and identify patterns that impact your well-being. Our intuitive tracker helps you stay aware of your emotional fluctuations."
  },
  {
    title: "Guided Meditation and Breathing Exercises",
    description: "Access a curated library of guided meditations and breathing exercises designed to help you relax, reduce stress, and improve mental clarity."
  },
  {
    title: "Daily Affirmations",
    description: "Start your day with positive affirmations. Our daily messages are crafted to inspire and motivate you towards better mental health."
  },
  {
    title: "24/7 AI Assistant - Bright",
    description: "Meet Bright, your always-available AI assistant. Whether you need immediate support or just a friendly chat, Bright is here to help you anytime, anywhere."
  },
  {
    title: "Therapist-Patient Bonding Tools",
    description: "Strengthen your therapeutic relationships with tools designed to enhance communication and understanding between you and your therapist."
  }
];

function Features() {
  return (
    <section id="features" className="section">
      <h2>Our Features</h2>
      <p className="section-intro">At BrightLyf, we offer a suite of features designed to support and enhance your mental well-being. Explore our key offerings below.</p>
      <div className="feature-grid">
        {featuresData.map((feature, index) => (
          <div key={index} className="feature-item">
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Features;