// components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header>
      <Link to="/" className="nav-logo">BrightLyf</Link>
      <nav>
        <a href="#features">Features</a>
        <a href="#research">Research</a>
        <a href="#contact">Contact</a>
      </nav>
    </header>
  );
}

export default Header;